<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <template v-slot:default>
        <el-button
          icon="el-icon-download"
          size="small"
          type="success"
          style="margin-right: 10px"
          :loading="excelLoading"
          @click="exportExcel"
          >{{ excelLoading ? "正在导出" : "导出Excel" }}
        </el-button>
      </template>
    </GlobalForm>
  </div>
</template>

<script>
import { guideExcelAction } from "@/utils/common";
import moment from "moment";
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      SeachParams: {},
      ackTime: [],
      excelLoading: false,
      allExcelLoading: false,
      initData: {
        signTime: [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
        imei: "",
        ackTime: null,
        pauseTime: null,
        finishTime: null,
        orderNo: this.$route.query.order || "",
      },
      // 搜索参数
      formItemList: [
        {
          labelName: "订单号",
          key: "orderNo",
          type: "input",
          lableWidth: "70px",
          placeholder: "请输入回收单号查询",
        },
        {
          lableWidth: "80px",
          labelName: "旧机串号",
          key: "imei",
          type: "input",
          placeholder: "请输入旧机串号查询",
        },
        {
          labelName: "外部单号",
          key: "outOrderNo",
          type: "input",
          placeholder: "请输入外部单号查询",
          lableWidth: "80px",
        },
        {
          labelName: "发货快递单号",
          key: "expressNo",
          type: "input",
          placeholder: "请输入完整快递单号查询",
          lableWidth: "110px",
        },
        {
          labelName: "回收商",
          key: "manualMerchantId",
          clear: true,
          lableWidth: "70px",
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "成交时间",
          key: "ackTime",
          lableWidth: "80px",
          type: "daterange",
        },
        {
          labelName: "订单签收时间",
          key: "signTime",
          lableWidth: "110px",
          type: "daterange",
        },
        {
          labelName: "暂停收货时间",
          key: "pauseTime",
          lableWidth: "110px",
          type: "daterange",
        },
        {
          labelName: "完成收货时间",
          key: "finishTime",
          lableWidth: "110px",
          type: "daterange",
        },
        {
          labelName: "是否入库",
          key: "isInto",
          type: "select",
          lableWidth: "80px",
          placeholder: "请选择",
          option: [
            {
              label: "是",
              value: "00",
            },
            {
              label: "否",
              value: "01",
            },
          ],
        },
        {
          labelName: "是否出库",
          placeholder: "请选择",
          key: "isOut",
          type: "select",
          lableWidth: "80px",
          option: [
            {
              label: "是",
              value: "00",
            },
            {
              label: "否",
              value: "01",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getSelectData();
  },
  mounted() {
    this.handleConfirm({ ...this.initData });
  },
  computed: {
    //催货状态
    trackState() {
      return this.$store.state.tagsView.trackState;
    },
    isFinishSenderAddress(){
      return this.$store.state.tagsView.isFinishSenderAddress;
    },
  },
  watch: {
    trackState(val) {
      console.log(val, "状态");
      if (val == "00"||val == "001") {
        this.initData.signTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
        this.initData.pauseTime=null
        this.initData.finishTime=null
      } else if (val == "01") {
        this.initData.pauseTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
        this.initData.signTime=null
        this.initData.finishTime=null
      } else if (val == "02") {
        this.initData.pauseTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
        this.initData.signTime=null
        this.initData.finishTime=null
      } else if (val == "03") {
        this.initData.finishTime = [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ];
        this.initData.signTime=null
        this.initData.pauseTime=null
      }
      this.handleConfirm({ ...this.initData });
    },
  },
  methods: {
    // 下拉列表数据
    async getSelectData() {
      const retrieveRes = await _api.listSimpleMerchantView({
        pageNum: 1,
        pageSize: 9999,
      });
      // 回收商家下拉
      if (retrieveRes.code === 1) {
        const hsIndex = this.formItemList.findIndex(
          (v) => v.key === "manualMerchantId"
        );
        this.formItemList[hsIndex].option = retrieveRes.data.map((v) => {
          return {
            label: v.merchantName,
            value: v.merchantId,
          };
        });
      }
    },
    handleConfirm(data, cd) {
      console.log(this.initData, "提交了form", data);
      let ackStartTime = "";
      let ackEndTime = "";
      let finishEndTime = "";
      let finishStartTime = "";
      let pauseEndTime = "";
      let pauseStartTime = "";
      let signEndTime = "";
      let signStartTime = "";
      if (data.ackTime === null) {
        ackStartTime = "";
        ackEndTime = "";
      } else {
        ackStartTime = Number(
          moment(data.ackTime[0]).startOf("day").format("x")
        );
        ackEndTime = Number(moment(data.ackTime[1]).endOf("day").format("x"));
      }
      if (data.pauseTime === null) {
        pauseEndTime = "";
        pauseStartTime = "";
      } else {
        pauseStartTime = Number(
          moment(data.pauseTime[0]).startOf("day").format("x")
        );
        pauseEndTime = Number(
          moment(data.pauseTime[1]).endOf("day").format("x")
        );
      }
      if (data.finishTime === null) {
        finishStartTime = "";
        finishEndTime = "";
      } else {
        finishStartTime = Number(
          moment(data.finishTime[0]).startOf("day").format("x")
        );
        finishEndTime = Number(
          moment(data.finishTime[1]).endOf("day").format("x")
        );
      }
      if (data.signTime === null) {
        signStartTime = "";
        signEndTime = "";
      } else {
        signStartTime = Number(
          moment(data.signTime[0]).startOf("day").format("x")
        );
        signEndTime = Number(moment(data.signTime[1]).endOf("day").format("x"));
      }
      this.SeachParams = {
        ackStartTime,
        ackEndTime,
        finishEndTime,
        finishStartTime,
        pauseEndTime,
        pauseStartTime,
        signEndTime,
        signStartTime,
        expressNo: data.expressNo,
        imei: data.imei,
        manualMerchantId: data.manualMerchantId,
        orderNo: data.orderNo,
        outOrderNo: data.outOrderNo,
        pageNum: 1,
        pageSize: 10,
        isInto: data.isInto,
        isOut: data.isOut,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.$store.commit("tagsView/SEACH_PARAMS", this.SeachParams);
    },
    // 导表
    exportExcel() {
      console.log(this.SeachParams);
      let excelParams = JSON.parse(JSON.stringify(this.SeachParams));
      excelParams.state = this.trackState || "00";
      excelParams.signPosi=this.isFinishSenderAddress===true?'01':this.isFinishSenderAddress===false?'':'',
      this.excelLoading = true;
      _api
        .hiuhoushouExcelOUT(excelParams)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "华为收货列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");

          this.excelLoading = false;
        })
        .catch((err) => {
          this.excelLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
